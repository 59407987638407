<template>
	<div>
		<div
			class="flex-column justify-center items-center"
		>
			<div>
				<button
					v-if="!is_prev"
					class=" mr-10"
					@click="prev"
				><v-icon>mdi-chevron-left</v-icon></button>
				<button
					@click="is_view = !is_view"
				>
					<span class="box-date bg-white size-px-14 mr-5"> {{ date1 }}</span>
					<v-icon class="size-px-20">mdi-calendar-month</v-icon>
				</button>
				<button
					v-if="!is_next"
					class=" ml-10"
					@click="next"
				><v-icon>mdi-chevron-right</v-icon></button>
			</div>
		</div>

		<PopupLayer
			v-if="is_view"
			style="z-index: 9999"
		>
			<template
				v-slot:body
			>
				<div
					v-if="type == 'year'"
					class="bg-white pa-20 justify-center gap-10"
				>
					<button
						v-for="y in years"
						:key="'year_' + y"
						class="box pa-10-20 radius-20"
						:class="date_picker.date == y ? 'bg-gray-light' : ''"
						@click="setDate(y)"
					>{{ y }}</button>
				</div>
				<v-date-picker
					v-else
					v-model="date1"
					no-title
					scrollable
					@change="setDate"
					style="width: 290px"
				></v-date-picker>
			</template>
		</PopupLayer>
	</div>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'DatePicker'
	, components: {PopupLayer}
	, props: ['type', 'date', 'is_prev', 'is_next']
	, data: function(){
		return {
			is_view: false
			, year: this.type == 'year' ? true : false
			, date1: this.date.split(' ').length > 0 ? this.date.split(' ')[0] : this.date
			, start: 2023
		}
	}
	, computed: {

		date_picker: function(){
			let t = ''
			let date = this.date ? this.date : this.type == 'year' ? this.$date.init().year : this.$date.getToday('-')
			let dd = date.split(' ')
			t = dd[0]
			return {
				date: t
			}
		}
		, years: function(){
			let t = []
			let y = this.$date.init(this.$date.getToday('-'), '-').year

			for(let i = this.start; i <= y; i++){
				console.log(i)
				t.push(i)
			}
			return t
		}
	}
	, methods: {
		setDate: function(date){
			this.$emit('click', date)
			this.date1 = date
			this.is_view = false
		}
		, next: function(){
			let date = this.$date.init()
			let max = date.year

			if(this.date_picker.date >= max){
				return false
			}else{
				this.date_picker.date++
				this.setDate(this.date_picker.date)
			}
		}
		, prev: function(){
			this.date_picker.date--
			this.setDate(this.date_picker.date)
		}
	}
	, created() {
	}
}
</script>

<style>
.v-date-picker-table .v-btn.v-btn--active { color: black !important;}
</style>