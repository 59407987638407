<template>
	<div class="full-height flex-column">
		<div
			class="mt-50 pa-10-20"
		>
			<div class="pa-20">
				<DatePicker class="mt-20" type="year" :date="search_year" @click="setDate($event)" ></DatePicker>
			</div>

			<div
				class="mt-20"
			>
				<ul
					v-if="items.length > 0"
					class="size-px-14"
				>
					<li
						v-for="(history, h_index) in list_history"
						:key="'history_' + h_index"
						class="under-line-not-last pb-20 mb-20"
						@click="onPayment(history)"
					>
						<div
							class="justify-space-between items-center"
						>
							<div>{{ $language.point_result.title_payment_type }} <!-- 결제 수단 --></div>
							<div
							>{{ history.payment_type_code_name }}</div>
						</div>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>{{ $language.point_result.title_payment_amount }} <!-- 결제 금액--></div>
							<div>{{ history.payment_amount | makeComma }}원</div>
						</div>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>{{ $language.point_result.title_payment_status }} <!-- 결제상태 --></div>
							<div :class="history.payment_state_color">{{ history.payment_state_code_name }}</div>
						</div>

						<div
							class="justify-space-between items-center mt-10"
						>
							<div>{{ $language.point_result.title_payment_date }} <!-- 거래일시 --></div>
							<div>{{ history.pmtdate }}</div>
						</div>
					</li>
				</ul>
				<Empty
					v-else

				></Empty>
			</div>
		</div>

		<PopupLayer
			v-if="is_on_payment"
		>
			<template
				v-slot:body
			>
				<div
					class="bg-white pa-10-20 radius-20 full-height flex-column"
				>
					<div
						class="pa-10-20 under-line size-px-20 text-center color-blue-mafia font-weight-bold"
					>{{ $language.point_result.title_payment_detail }} <!-- 결제 상세내역 --></div>

					<div
						class="mt-10 size-px-14 full-height flex-column"
					>
						<div>
							<div class="under-line pb-10">
								<div class="size-px-16 font-weight-bold">{{ $language.point_result.title_information }} <!-- 정보 --></div>
								<div class="mt-10 justify-space-between">
									<span>{{ $language.point_result.title_product }} <!-- 상품 --></span>
									<span>{{ item_payment.point_name }} {{ item_payment.charg_point }}개</span>
								</div>
							</div>
							<div class="mt-10">
								<div class="size-px-16 font-weight-bold">{{ $language.point_result.title_payment_information }} <!-- 결제정보 --></div>
								<div class="mt-10 justify-space-between">
									<span>{{ $language.point_result.title_tid }} <!-- 거래번호 --></span>
									<span>{{ item_payment.order_number }}</span>
								</div>
								<div class="mt-10 justify-space-between">
									<span>{{ $language.point_result.title_credit }} <!-- 카드 --></span>
									<span>{{ item_payment.payment_mthd_name }}</span>
								</div>
								<div class="mt-10 justify-space-between">
									<span>{{ $language.point_result.title_payment_number }} <!-- 승인번호 --></span>
									<span>{{ item_payment.leglter_payment_approval_number }}</span>
								</div>
								<div class="mt-10 justify-space-between">
									<span>{{ $language.point_result.title_payment_div }} <!-- 거래종류 --></span>
									<span>{{ installment }}</span>
								</div>
								<div class="mt-10 justify-space-between">
									<span>{{ $language.point_result.title_payment_amount }} <!-- 결제 금액 --></span>
									<span>{{ item_payment.amount | makeComma }}</span>
								</div>
								<div class="mt-10 justify-space-between">
									<span>{{ $language.point_result.title_payment_date }} <!-- 거래일시 --></span>
									<span>{{ item_payment.pmtdate }}</span>
								</div>
							</div>
						</div>

						<div
							class="mt-auto btn_area pt-30"
						>
							<button
								class="btn_l btn_fill_blue"
								@click="offPayment"
							>{{ $language.point_result.btn_confirm }} <!-- 확인 --></button>
						</div>
					</div>
				</div>
			</template>
		</PopupLayer>
	</div>
</template>

<script>

import PopupLayer from "@/view/Layout/PopupLayer";
import DatePicker from "@/components/DatePicker";
import Empty from "@/view/Layout/Empty";
export default {
	name: 'PaymentHistory'
	, components: {Empty, DatePicker, PopupLayer}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'payment history'
				, title: '불렛 결제내역'
				, not_footer: true
				, type: 'mypage'
			}
			, items: [
			]
			, is_on_payment: false
			, item_payment: {

			}
			, search_year: ''
		}
	}
	, computed: {
		installment: function(){
			let t = '-'
			if(this.item_payment.installment > 0){
				t = this.item_payment.installment + '개월'
			}else{
				t = '일시불'
			}
			return t
		}
		, list_history: function(){
			return this.items.filter( (item) => {
				switch (item.payment_state_code){
					case 'PM00400007':
						item.payment_state_color = 'color-red'
						break;
				}

				return item
			})
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_user_payment_list
					, data: {
						member_number: this.user.member_number
						, search_year: this.search_year + ''
					}
					, type: true
				})

				if(result.success){
					this.items = result.data.point_payment_particulars_list
					if(this.items.length > 0){
						this.item_last = this.items[this.items.length - 1]
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, onPayment: async function(item){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_user_payment_detail
					, data: {
						member_number: this.user.member_number
						, point_number: item.point_number
						, charg_payment_number: item.charg_payment_number
						, payment_type_code: item.payment_type_code
					}
					, type: true
				})

				if(result.success){
					this.is_on_payment = true
					this.item_payment = result.data
					this.item_payment.order_number = item.charg_payment_number
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, offPayment: function(){
			this.is_on_payment = false
			this.item_payment = {}
		}
		, to: function(type){
			this.$bus.$emit('to', { name: 'PaymentHistory', params: { type: type}})
		}
		, setDate: function(date){
			this.search_year = date
			this.getData()
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>
